import React, { useState, useEffect } from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from 'components/layout'
import ConfirmationMessage from 'components/confirmation-message'
import StartOverError from 'components/start-over-error'
import { useSignupObject } from 'hooks/use-signup-object'
import { getSignupErrorMsg } from 'lib/signup-form-helpers'
import {
  ContentfulComponentHeader,
  ContentfulLayout,
  ContentfulRichText,
} from 'types/contentful'

type DataProps = {
  contentfulPageConfirmation: {
    domain: string
    partnerSlug: string
    programSlug: string
    layout: ContentfulLayout
    header: ContentfulComponentHeader
    heading: string
    body: ContentfulRichText
    nonStandardFlowBody: ContentfulRichText
  }
}

const ConfirmationIndex: React.FC<PageProps<DataProps>> = (props) => {
  const {
    domain,
    partnerSlug,
    programSlug,
    layout,
    header,
    heading,
    body,
    nonStandardFlowBody,
  } = props.data.contentfulPageConfirmation

  const [signUpToken, setSignUpToken] = useState<string | null>()
  const [error, setError] = useState<string | null>()
  const [displayDevices, setDisplayDevices] = useState(false)

  const { signupObj, loading } = useSignupObject({
    signUpToken,
    onError: () => {
      setError(
        'There was an error loading your information. Your token may have expired.'
      )
    },
  })

  const [pathParam, setPathParam] = useState<string | null>()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    setSignUpToken(urlParams.get('signUpToken'))
    setPathParam(urlParams.get('path'))
    setDisplayDevices(urlParams.get('displayDevices') === '1')
  }, [])

  useEffect(() => {
    if (signupObj) {
      setError(getSignupErrorMsg(signupObj))
    }
  }, [signupObj])

  return (
    <Layout layout={layout} header={header}>
      <section className="confirmation-container">
        <div className="container">
          {loading && <p>Loading...</p>}
          {!loading && error && (
            <StartOverError
              error={error}
              domain={domain}
              partnerSlug={partnerSlug}
              programSlug={programSlug}
              pathParam={pathParam}
            />
          )}
          {signupObj && !error && (
            <ConfirmationMessage
              title={heading}
              message={
                pathParam &&
                pathParam.toUpperCase() != 'ENROLL' &&
                nonStandardFlowBody
                  ? nonStandardFlowBody
                  : body
              }
              signupObj={signupObj}
              displayDevices={displayDevices}
            />
          )}
        </div>
      </section>
    </Layout>
  )
}

export default ConfirmationIndex

export const pageConfirmationQuery = graphql`
  query ConfirmationQuery($contentful_id: String!) {
    contentfulPageConfirmation(contentful_id: { eq: $contentful_id }) {
      contentful_id
      domain
      partnerSlug
      programSlug
      layout {
        ...LayoutFields
      }
      header {
        ...HeaderFields
      }
      heading
      body {
        raw
      }
      nonStandardFlowBody {
        raw
      }
    }
  }
`
