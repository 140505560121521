import React from 'react'
import AllDeviceStatuses from 'components/all-device-statuses'
import ConfirmationResults from 'components/confirmation-results'
import { ContentfulRichText } from 'types/contentful'
import { UWPSignup } from 'types/uwp'

import * as styles from './confirmation-message.module.css'
import RichText from './rich-text'
import { Script } from 'gatsby'

type ConfirmationMessageProps = {
  title: string
  message: ContentfulRichText
  signupObj: UWPSignup
  displayDevices: boolean
}

const ConfirmationMessage = ({
  title,
  message,
  signupObj,
  displayDevices,
}: ConfirmationMessageProps) => {
  const enrollmentObj = signupObj?.eligibilityResult?.formFillingEnrollment

  return (
    <div className={styles.confirmationMessage}>
      <Script type="text/javascript" id="vwoSuccessEvent">
        {`
          window.VWO = window.VWO || [];
          VWO.event = VWO.event || function () {VWO.push(["event"].concat([].slice.call(arguments)))};
          VWO.event("successfulSignUpSubmission");
        `}
      </Script>
      <h1>{title}</h1>
      <RichText data={message} classes={styles.confirmationRichText} />
      {displayDevices && <AllDeviceStatuses signupObj={signupObj} />}
      <ConfirmationResults enrollmentObj={enrollmentObj} />
    </div>
  )
}

export default ConfirmationMessage
